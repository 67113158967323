import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/Users/jxie/projects/lyra/packages/web/src/components/content/ContentPage.tsx";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Paper = makeShortcode("Paper");
const GoTo = makeShortcode("GoTo");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "things-to-avoid"
    }}>{`Things to avoid`}</h1>
    <h3 {...{
      "id": "reacting-back-the-same-way"
    }}><strong parentName="h3">{`Reacting back the same way`}</strong></h3>
    <p>{`When we are faced with negative emotions we tend to react back with negative emotions ourselves. For example, if someone is yelling and shouting at you, your gut reaction may be to yell and shout back in an effort to defend yourself or end the conversation. Reacting back the same way can lead to a downward spiral and everyone ends up feeling (more) upset.`}</p>
    <h3 {...{
      "id": "shutting-the-emotions-down"
    }}><strong parentName="h3">{`Shutting the emotions down`}</strong></h3>
    <p>{`Because we find negative emotions hard to handle or overwhelming, sometimes we just want to shut them down. Unfortunately, shutting down expressions of strong emotions or a sensitive conversation doesn't teach your teenager how to deal with these feelings or the difficult situations that led to them.`}</p>
    <p>{`Here are some examples of things to avoid:`}</p>
    <p><strong parentName="p">{`Dismissing or trivialising your teenager's emotional responses, even if you think their concerns are unreasonable or if hearing about these feelings makes you uncomfortable.`}</strong></p>
    <ul>
      <li parentName="ul"><em parentName="li">{`"Don't be so sensitive."`}</em></li>
      <li parentName="ul"><em parentName="li">{`"Get over it."`}</em></li>
    </ul>
    <p><strong parentName="p">{`Responding in a way that can lead your teenager to believe that their emotions are wrong.`}</strong></p>
    <ul>
      <li parentName="ul"><em parentName="li">{`"Why are you crying like a baby?"`}</em></li>
      <li parentName="ul"><em parentName="li">{`"It can't be that bad."`}</em></li>
    </ul>
    <p><strong parentName="p">{`Responding in a way that can make your teenager think that they are bad for having certain emotions.`}</strong></p>
    <ul>
      <li parentName="ul"><em parentName="li">{`"You're such a scaredy-cat."`}</em></li>
      <li parentName="ul"><em parentName="li">{`"You're making everyone else feel miserable."`}</em></li>
    </ul>
    <Paper mdxType="Paper">
  <p>
    <strong>Remember…</strong>
  </p>
  <p>
    Your teenager is still developing their ability to manage the strong emotions they are experiencing. Sometimes this
    means that they may express their emotions in ways that you do not find acceptable, e.g. yelling in anger, slamming
    doors, speaking disrespectfully. When this happens, try your best to{' '}
    <strong>separate their emotions from their behaviours</strong>. Remain calm, listen well, and show concern{' '}
    <i>for how they are feeling</i> (the emotions underlying the behaviours). Then, when things are calm(er), you can
    have a chat with your teenager about the <i>behaviours</i> that you found unacceptable, and discuss ways they can
    better express their strong emotions in the future. For more tips on this, see the modules{' '}
    <GoTo to="/dashboard" mdxType="GoTo">
      <i>Raising good kids into great adults</i>{' '}
    </GoTo>
    and <GoTo to="/dashboard" mdxType="GoTo">
      <i>Calm versus conflict</i>
    </GoTo>.
  </p>
    </Paper>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      